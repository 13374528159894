<template>
  <v-container fluid grey lighten-3 pa-5>
    <!-- Summary section -->

    <v-snackbar
      v-model="snackbar.active"
      bottom
      color="yellow darken-2"
      :timeout="3000"
    >
      <v-icon light small class="mr-1">check_circle</v-icon>
      <b class="black--text">{{ snackbar.msg }}</b>
      <v-btn light text @click="snackbar.active = false">
        ปิด
      </v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="snackbarFaild.active"
      bottom
      color="error"
      :timeout="3000"
    >
      <v-icon dark small class="mr-1">cancel</v-icon>
      <b>{{ snackbarFaild.msg }}</b>
      <v-btn dark text @click="snackbarFaild.active = false">
        ปิด
      </v-btn>
    </v-snackbar>

    <v-row class="mt-5">
      <v-col lg="4" md="4" sm="12" cols="12">
        <OrderTotalCard
          v-if="isAdminLevel"
          title="TOTAL"
          avatar_bg="blue"
          avatar_icon="mdi-cart-outline"
          type="total"
          status="total"
          ref="totalCard"
        />
      </v-col>

      <v-col lg="4" md="4" sm="12" cols="12">
        <OrderTotalCard
          v-if="isAdminLevel"
          title="COD จ่ายปลายทาง"
          avatar_bg="green"
          avatar_icon="cloud_queue"
          type="cod"
          status="total"
          ref="codCard"
        />
      </v-col>

      <v-col lg="4" md="4" sm="12" cols="12">
        <OrderTotalCard
          v-if="isAdminLevel"
          title="โอนเงิน"
          avatar_bg="#dd4b39"
          avatar_icon="local_mall"
          type="tranfer"
          status="total"
          ref="tranferCard"
        />
      </v-col>
    </v-row>

    <!-- Table section -->

    <!-- bottom sheet component -->
    <OrderDetailSheet :id="orderIdSheet" @clear-id="orderIdSheet = ''"></OrderDetailSheet>
    <!-- end bottom sheet component -->

    <!-- filter brand and date picker -->
    <v-row>
      <v-col class="mb-0 pb-0">
        <b>ค้นหา รายการสั่งซื้อ</b>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="2">
        <v-select
          v-model="filterBranchId"
          :items="branches"
          label="สาขา"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>

      <v-col cols="12" md="2">
        <v-select
          v-model="filterStatus"
          :items="itemsMenuAction"
          label="สถานะ"
          item-text="title"
          item-value="actionType"
        ></v-select>
         </v-col>

         <v-col cols="12" md="2">
          <v-select
            v-model="filterTransporter"
            :items="transporterMenuAction"
            label="บริษัทขนส่ง"
            item-text="title"
            item-value="actionType"
          ></v-select>
      </v-col>

      <v-col cols="12" md="2">
        <v-dialog
          ref="dialogDatepick"
          v-model="date_modal"
          :return-value.sync="dates"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateRangeText"
              label="เลือก วันที่"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dates" range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="date_modal = false"
              >Cancel</v-btn
            >
            <v-btn
              text
              color="primary"
              @click="$refs.dialogDatepick.save(dates)"
              >OK</v-btn
            >
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="12" md="3">
        <v-btn text class="mt-4 pl-0 ml-0" @click="doSearch"
          ><v-icon>search</v-icon> ค้นหา</v-btn
        >
        <v-btn text class="mt-4 pl-0 ml-0" @click="resetSearch"> ยกเลิก</v-btn>
      </v-col>
    </v-row>

    <v-card>
      <v-data-table
        mobile-breakpoint="200"
        v-model="selected"
        :headers="headers"
        :items="mDataArray"
        :options.sync="options"
        :server-items-length="totalItems"
        :footer-props="{ 'items-per-page-options': [50, 100, 200] }"
        show-select
      >
        <!-- table top section -->
        <template v-slot:top>
          <v-toolbar flat color="blue darken-1" :dark="true" height="55">
            <v-badge size="18" color="yellow accent-4">
              <template v-slot:badge>
                <span class="black--text">{{ selected.length }}</span>
              </template>
              เลือก
            </v-badge>
            <v-divider class="mx-4 mr-1" inset vertical></v-divider>
            <v-btn @click="handlePastetxt" x-small class="mr-2" text><v-icon size="20">mdi-content-paste</v-icon></v-btn>
            <v-text-field
              v-model.trim="search"
              name="search"
              label="พิมพ์ ชื่อ | เบอร์โทร | Tracking"
              id="search"
              single-line
              hide-details
              autocomplete="off"
            ></v-text-field>

            <v-spacer></v-spacer>
            <v-spacer></v-spacer>

            <v-menu subheader>
              <template v-slot:activator="{ on }">
                <v-btn class="mb-1" icon dark v-on="on">
                  <v-icon class="ml-1">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-subheader>เมนู</v-subheader>
                 <v-list-item @click="printOrder">
                    <v-list-item-icon><v-icon>print</v-icon></v-list-item-icon>
                    <v-list-item-content>
                    <v-list-item-title>Print</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                 <v-list-item @click="exportFlash">
                    <v-list-item-icon><v-icon>mdi-file-excel</v-icon></v-list-item-icon>
                    <v-list-item-content>
                    <v-list-item-title>Export xlsx</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-subheader>เปลี่ยน สถานะ</v-subheader>
                <v-list-item
                  v-for="(item, index) in itemsMenuAction"
                  :key="index"
                  @click="doActionMenu(item)"
                > 
                <v-list-item-content>
                  <v-list-item-title class="body-2">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
                 <v-divider></v-divider>
                <v-subheader>Scan Barcode</v-subheader>
                <v-list-item @click="doScan">
                   <v-list-item-content>
                      <v-list-item-title>เพิ่มไปรายการ Scan</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>

          </v-toolbar>
        </template>

        <template v-slot:item="{ item, select, isSelected }">
          <tr>
            <td>
              <v-simple-checkbox
                @input="select"
                :value="isSelected"
                :ripple="false"
              ></v-simple-checkbox>
            </td>
            <td>
              <Transporter :id="item.transporter" />
            {{item.id}}</td>
            <td>
              <OrderStatus :status="item.status"></OrderStatus>
               <div class="my-td-width-status" v-if="item.tracking">
                 <v-tooltip close-delay="500" :open-on-hover="false" open-on-click top :key="`a_${item.id}`">
                  <template v-slot:activator="{ on,attrs }">
                    <span v-bind="attrs" v-on="on" class="caption grey--text text--darken-3" @click="copyTrackingText(item.tracking)">{{item.tracking}}</span>
                  </template>
                  <span>copied</span>
                </v-tooltip>
                <v-tooltip close-delay="500" top :key="item.id">
                  <template v-slot:activator="{ on,attrs }">
                      <v-btn
                        icon 
                        v-bind="attrs"
                        v-on="on"
                        color="grey"
                        text
                        x-small
                        @click.prevent="copyTracking(item.tracking)"
                        ><v-icon x-small>mdi-content-copy</v-icon></v-btn
                      >
                  </template>
                  <span>copy!</span>
                </v-tooltip>
              </div>
            </td>

            <td class="caption">
              {{ item.branch }}
            </td>
            <td class="pt-2 pb-2">
              <v-chip
                label
                :dark="true"
                outlined
                color="blue darken-3"
                small
                v-if="item.tag"
              >
                {{ item.tag }}
              </v-chip> {{ item.name }}
              <div class="grey--text text--darken-3 caption my-td-width3"><v-icon color="blue lighten-2" size="15">mdi-phone</v-icon> {{ item.tel1 }}, {{ item.tel2}}</div>
              <div v-if="item.remark_admin">
                <v-icon size="15" color="orange darken-2">error_outline</v-icon>
                <span class="caption grey--text text--darken-3 yellow lighten-2 ml-1">{{ item.remark_admin }}</span>
              </div>
              <div v-if="item.tags">
                <v-chip x-small v-for="(tag, tindex) in item.tags.item" :key="tindex" class="mr-1">{{tag.name}}</v-chip> 
              </div>
            </td>
            <td>
              <v-chip
                color="green darken-3"
                :dark="true"
                v-if="item.is_cod == paymentMethods[1].id"
                class="ma-2 pa-1"
                small
                outlined
                label
              >
                <b>COD{{ item.amount | currency(" ฿", 0) }}</b>
              </v-chip>
              <v-chip light v-else class="ma-2 pa-1" outlined small label>
                PAID {{ item.amount | currency("฿", 0) }}
              </v-chip>

              <div v-if="item.cod_status == 1" class="caption pa-1">
                <v-icon size="12" color="blue darken-1">check_circle</v-icon>
                paid
                {{ item.cod_paid | moment("YYYY-MM-DD") }}
              </div>
            </td>

            <td>
              <v-select
                class="my-select-style2"
                v-model="item.status"
                :items="statusSelect"
                :rules="[v => !!v || 'กรุณาเลือก']"
                label="สถานะ"
                item-text="name"
                item-value="id"
                @change="changeStatus(item)"
              ></v-select>
            </td>

            <td class="caption">
              <div class="my-td-width">
                <v-icon color="orange" size="18">mdi-update</v-icon
                >{{ item.createdAt | moment("DD-MM-YYYY HH:mm") }}
              </div>
              <div v-if="item.createdAt != item.updatedAt"
                  ><v-icon color="green" small>mdi-update</v-icon
                  >{{ item.updatedAt | moment("DD-MM-YYYY HH:mm") }}</div
                >
            </td>
            <td>
              <v-icon size="18" class="mr-1" @click="orderIdSheet = item.id">airplay</v-icon>
              <v-icon size="18" class="mr-2" @click="editItem(item)">search</v-icon>
              <!-- <router-link :to="{ name: 'BillingEdit', params: { id: item.id }}" target="_blank" style="text-decoration: none;">
                  <v-icon title="เปิดหน้าใหม่">mdi-file-find</v-icon>  
                </router-link> -->
              <v-icon size="18" class="mr-1" @click="deleteItem(item)">delete</v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="confirmDeleteDlg" max-width="290">
      <v-card>
        <v-card-title class="headline">Confirm Delete</v-card-title>
        <v-card-text class="body">
          ต้องการลบรายการนี้ใช่หรือไม่?
          <h3>{{ selectedItem }}</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmDeleteDlg = false"> cancel </v-btn>
          <v-btn color="error" text @click="confirmDelete">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmChangeStatusDlg" max-width="290">
      <v-card>
        <v-card-title class="headline">เปลี่ยนสถานะ</v-card-title>
        <v-card-text class="body">
          ต้องการเปลี่ยนสถานะเป็น
          <h3>
            <span class="blue--text">{{ selectedStatusAction.title }}</span>
            ใช่หรือไม่?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmChangeStatusDlg = false"> cancel </v-btn>
          <v-btn color="error" text @click="doChangeStatus">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogloading" hide-overlay persistent width="300">
      <v-card color="blue lighten-1" dark>
        <v-card-text>
          Please wait..
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api, {
  ORDERSTATUS,
  PAYMENTMETHOD,
  ORDERID_PREFIX,
  SCANSTATUS,
  TRANSPORTER
} from "@/services/api";
import OrderTotalCard from "@/components/cards/OrderTotalCard";
import OrderDetailSheet from "@/components/OrderDetailSheet";
import Transporter from "@/components/helpers/Transporter";
import OrderStatus from "@/components/helpers/OrderStatus";
import { userLevel } from "@/components/mixins/userLevel";
import Exceljs from "exceljs";
import { saveAs } from "file-saver";
import _ from "lodash";

export default {
  name: "Order",
  mixins: [userLevel],
  data() {
    return {
      orderIdSheet: '',
      isadmin: false,
      itemsMenuAction: [
        { title: "รอ", actionType: ORDERSTATUS.WAIT },
        { title: "รอจัดการ", actionType: ORDERSTATUS.PENDING },
        { title: "PRINTED", actionType: ORDERSTATUS.PRINTED },
        { title: "KA-Exported", actionType: ORDERSTATUS.KAEXPORTED },
        { title: "KA-Booking", actionType: ORDERSTATUS.BOOKING },
        { title: "KA-PRINTED", actionType: ORDERSTATUS.KAPRINTED },
        { title: "KERRY-Exported", actionType: ORDERSTATUS.KERRYEXPORTED },
        { title: "KERRY-Printed", actionType: ORDERSTATUS.KERRYPRINTED },
        { title: "KERRY-Booking", actionType: ORDERSTATUS.KERRYBOOKING },
        { title: "สำเร็จ", actionType: ORDERSTATUS.COMPLETED },
        { title: "ยกเลิก", actionType: ORDERSTATUS.CANCELLED },
        { title: "Pre-Order สั่งจอง", actionType: ORDERSTATUS.PREORDER }
      ],
      transporterMenuAction: [
        {title: "Kerry Express", actionType: TRANSPORTER.KERRYEXPRESS},
        {title: "Flash Express", actionType: TRANSPORTER.FLASHEXPRESS}
      ],
      copySucceeded: null,
      filterBranchId: "",
      filterStatus: "",
      filterTransporter: "",
      branches: [],
      dates: ["", ""],
      date_modal: false,
      dialogloading: false,
      confirmChangeStatusDlg: false,
      selectedStatusAction: "",
      selected: [],
      snackbar: {
        active: false,
        msg: ""
      },
      snackbarFaild: {
        active: false,
        msg: ""
      },
      statusSelect: [
        { id: ORDERSTATUS.WAIT, name: "รอ" },
        { id: ORDERSTATUS.PENDING, name: "รอจัดการ" },
        { id: ORDERSTATUS.PRINTED, name: "PRINTED" },
        { id: ORDERSTATUS.KAEXPORTED, name: "KA-Exported" },
        { id: ORDERSTATUS.BOOKING, name: "KA-Booking" },
        { id: ORDERSTATUS.KAPRINTED, name: "KA-PRINTED" },
        { id: ORDERSTATUS.KERRYEXPORTED, name: "KERRY-Exported" },
        { id: ORDERSTATUS.KERRYPRINTED, name: "KERRY-Printed" },
        { id: ORDERSTATUS.KERRYBOOKING, name: "KERRY-Booking" },
        { id: ORDERSTATUS.COMPLETED, name: "สำเร็จ" },
        { id: ORDERSTATUS.CANCELLED, name: "ยกเลิก" },
        { id: ORDERSTATUS.PREORDER, name: "Pre-Order" },
      ],
      paymentMethods: [
        { id: PAYMENTMETHOD.TRANFER, name: "โอนเงิน" },
        { id: PAYMENTMETHOD.COD, name: "COD จ่ายปลายทาง" }
      ],
      mDataArray: [],
      headers: [
        {
          text: "Id",
          align: "left",
          sortable: false,
          value: "id",
          width: 60
        },
        { text: "Status", value: "status" },
        { text: "Branch", value: "branch" },
        { text: "Name", value: "name" },
        { text: "Amount", value: "amount" },
        { text: "Method", value: "method" },
        { text: "เข้าระบบ / อัพเดต", value: "date" },
        { text: "Action", value: "action" }
      ],
      search: "",
      selectedItemId: "",
      confirmDeleteDlg: false, // false เท่ากับไม่ให้แสดง
      selectedItem: "",
      dataTableLoading: true,
      totalItems: 0,
      options: {} // for dataTable
    };
  },
  components: {
    // register ตัว components ที่เราทำการ import เข้ามาให้สามารถใช้งานได้
    OrderStatus,
    OrderTotalCard,
    OrderDetailSheet,
    Transporter
  },
  mounted() {
    console.log("this user components is running");
    //this.loadData();
    this.getBranches();
  },
  methods: {
    async handlePastetxt() {
      try {
        const data = await navigator.clipboard.readText();
        this.search = data.trim().substring(0,40)
      } catch (e) {
        console.log('empty data')
      }
    },
    handleCopyStatus(status) {
      this.copySucceeded = status;
    },
    copyTrackingText(tracking) {
      this.$copyText(tracking).then(
        function(e) {
          // alert('Copied')
        },
        function(e) {
          // alert('Can not copy')
          console.log(e);
        }
      );
    },
    copyTracking(tracking) {
      let linktrack = "";
      if (tracking.indexOf("PSR") == 0) {
        linktrack = `https://th.kerryexpress.com/th/track/v2/?track=${tracking}`;
      } else if (tracking.indexOf("TH") == 0) {
        linktrack = `https://www.flashexpress.co.th/tracking/?se=${tracking}`;
      } else if (tracking.indexOf("ALP") == 0) {
        linktrack = "http://www.alphafast.com/th/track?id=" + tracking;
      }

      this.$copyText(linktrack).then(
        function(e) {
          // alert('Copied')
          // console.log(e)
        },
        function(e) {
          // alert('Can not copy')
          console.log(e);
        }
      );
    },
    resetSearch: _.debounce(function() {
      this.dates = ["", ""];
      this.filterBranchId = "";
      this.filterStatus = "";
      this.filterTransporter = "";
      this.selected = [];
      if (this.search == "") {
        this.loadData();
      } else {
        this.search = "";
      }
    }, 700),
    doSearch: _.debounce(function() {
      this.selected = [];
      if (this.options.page == 1) {
        this.loadData();
      } else {
        this.options.page = 1;
      }
    }, 1000),
    printOrder(type) {
      if (this.selected.length > 0) {
        this.$store.dispatch({
          type: "selectItems",
          items: this.selected
        });
        this.$store.dispatch({
          type: "hideMenu",
          isHide: false
        });
        // print no barcode
          this.$router.push(
            `/printorder/filter/all/timestamp/` + Math.floor(Date.now()/1000)
          );
        
      } else {
        console.log("no value");
      }
    },
    async doScan() {
      if (this.selected.length > 0) {
        if (confirm('คุณต้องการเพิ่มข้อมูลไปรายการ SCAN ใช่หรือไม่?')) {
            let items = this.selected;
            let itemsScan = _.map(items, item => {
              return {ref_order_id: item.id, qty: 1, status: SCANSTATUS.PENDING};
            });

            let formData = new FormData();
            formData.append("items", JSON.stringify(itemsScan));
            let result = await api.insertToOrderScan(formData);
            if (result) {
              // update success
              this.snackbar = {
                active: true,
                msg: `เพิ่มข้อมูลรายการ SCAN สำเร็จ`
              };

              this.selected = [];

            } else {
              // update failed
              this.snackbarFaild = {
                active: true,
                msg: `เพิ่มข้อมูล SCAN ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`
              };
            }
          }
        }
    },
    async exportFlash() {
      let itemsSelected = this.selected;
      if (itemsSelected.length > 0) {
        let ids = _.map(itemsSelected, item => {
          return item.id;
        });

        let formData = new FormData();
        formData.append("items", JSON.stringify(ids));

        this.dialogloading = true; // show dialog loading
        let result = await api.getOrdersById(formData);
        if (result.data.result == "ok") {
          let orders = JSON.parse(result.data.message);

          let mData = _.map(orders, order => {
               let order_id = ORDERID_PREFIX + _.padStart(order.id, "5", "0");
                let addrinfo2 = order.address_info2;
                let post_code = addrinfo2.substring(
                  addrinfo2.length - 5,
                  addrinfo2.length
                );
                let amount = "";
                if (order.is_cod == PAYMENTMETHOD.COD) {
                  if (order.amount > 0) {
                    amount = order.amount;
                  }
                }
                 //splitting district
                      let address_split = order.address_info2.split(", ");
                      let new_address = '';
                      if (address_split[2].trim() == 'กรุงเทพ') {
                        new_address = `แขวง ${address_split[0]} เขต ${address_split[1]} ${address_split[2]}`;                 
                      } else {
                        new_address = `ตำบล ${address_split[0]} อำเภอ ${address_split[1]} ${address_split[2]}`;
                      }

                return {
                  Customer_order_number: order_id,
                  Consignee_name: order.name,
                  Address: order.address_info + " " + new_address,
                  Postal_code: parseInt(post_code),
                  Phone_number: order.tel1,
                  Phone_number2: "",
                  COD: amount,
                  Item_type: "อื่นๆ",
                  Weight_kg: 1,
                  Length: 1,
                  Width: 1,
                  Height: 1,
                  Freight_insurance: "",
                  Value_insurance: "",
                  Declared_value: "",
                  Packaging_damage_insurance: "",
                  Product_type: "Standard",
                  remark1: "",
                  remark2: "",
                  remark3: ""
                }; 
            
          });

          let workbook = new Exceljs.Workbook();
          workbook.creator = "pols";
          let worksheet = workbook.addWorksheet("My Order");
          worksheet.columns = [
            {
              header: "Customer_order_number",
              key: "Customer_order_number",
              width: 12
            },
            { header: "*Consignee_name", key: "Consignee_name", width: 30 },
            { header: "*Address", key: "Address", width: 70 },
            { header: "*Postal_code", key: "Postal_code", width: 10 },
            { header: "*Phone_number", key: "Phone_number", width: 15 },
            { header: "Phone_number2", key: "Phone_number2", width: 15 },
            { header: "COD", key: "COD", width: 10 },
            { header: "Item_type", key: "Item_type", width: 10 },
            { header: "*Weight_kg", key: "Weight_kg", width: 10 },
            { header: "Length", key: "Length", width: 10 },
            { header: "Width", key: "Width", width: 10 },
            { header: "Height", key: "Height", width: 10 },
            { header: "Freight_insurance", key: "Freight_insurance", width: 10 },
            { header: "Value_insurance", key: "Value_insurance", width: 10 },
            { header: "Declared_value", key: "Declared_value", width: 10 },
            { header: "Packaging_damage_insurance", key: "Packaging_damage_insurance", width: 10 },
            { header: "*Product_type", key: "Product_type", width: 10 },
            { header: "Remark1", key: "remark1", width: 10 },
            { header: "Remark2", key: "remark2", width: 10 },
            { header: "Remark3", key: "remark3", width: 10 }
          ];

          let total_exported = 0;
          _.forEach(mData, r => {
            if (typeof r != 'undefined') {
                total_exported+=1;
                worksheet.addRow(r);
            }
          });

          try {
            let dname = this.$moment().format("DDMMYYYY-HHmmss");
            let filename = ORDERID_PREFIX + "-ORDER-"+ total_exported + "-" + dname.toString() + ".xlsx";
            let databuffer = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([databuffer]), filename);
            this.dialogloading = false;
          } catch (error) {
            console.log("export excel error:", error.message);
            this.dialogloading = false; // hide dialog loading
          }
          orders = null;
          mData = null;
          workbook = null;
          result = null; 
        } else {
          // no data
        }
        ids = null;
      } else {
        alert("กรุณาเลือก order ที่ต้องการ export!");
      }
      itemsSelected = null;
      this.selected = [];
    },
    doActionMenu(actionType) {
      this.selectedStatusAction = actionType;
      this.confirmChangeStatusDlg = true;
    },
    doChangeStatus() {
      this.changeOrderStatus();
      this.confirmChangeStatusDlg = false;
    },
    async changeOrderStatus() {
      if (this.selected.length > 0) {
        let itemsSelected = this.selected;
        let ids = _.map(itemsSelected, item => {
          return item.id;
        });

        let formData = new FormData();
        formData.append("items", JSON.stringify(ids));
        formData.append("status", this.selectedStatusAction.actionType);
        let result = await api.updateBulkOrderStatus(formData);
        if (result) {
          // update success
          this.snackbar = {
            active: true,
            msg: `อัพเดตสถานะสำเร็จ`
          };

          this.selected = [];
          this.loadData();
        } else {
          // update failed
          this.snackbarFaild = {
            active: true,
            msg: `อัพเดตสถานะไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`
          };
        }
      }
    },
    searchItems: _.debounce(function() {
      // _.debounce ใน lodash ต่างจาก settimeout อย่างไร
      // 1. debounce จะ return ค่าออกมาเป็น function  สว่น   settimeout จะ return เป็น id
      // 2. debounce ตัวอย่างการใช้เช่น เวลาพิมพ์ search ข้อมูล function ใน debounce จะทำงานก็ต่อเมื่อเราได้หยุดพิมพ์ในเวลา 1.5 วิ แต่ถ้าในเวลา 1.5 วิ มีการพิมพ์ต่อ debounce ก็จะนับถอยหลังใหม่
      //     ซึ่งจะทำให้ การยิง request ไปที่ server ลดจำนวนการยิงลงจนกว่าเราจะพิมพ์ข้อความที่ต้องการค้นหาเสร็จ และไม่มีการพิมพ์ต่อเนื่องใน 1.5 วินั้นเอง
     if (this.options.page == 1) {
       this.loadData();
     } else {
       this.options.page = 1;
     }
    }, 2000),
    loadData() {
      this.getDataFromApi();
    },
    async getBranches() {
      let result = await api.getBranchActive();
      this.branches = result.data;
      result = null;
    },
    async getDataFromApi() {
      this.dataTableLoading = true;
      this.dialogloading = true;
        const { page, itemsPerPage } = this.options;
        let durations = "none";

        if (this.dates[0]) {
          if (this.dates.length == 1) {
            this.dates[1] = this.dates[0];
          }
          durations = this.dates.join("&");
        }

        let branchid = this.filterBranchId || "none";
        let filter_status = this.filterStatus || "none";
        let filter_transporter = this.filterTransporter || "none";
        let txtsearch = _.toUpper(this.search)
        let result = await api.getOrders(page, itemsPerPage, _.trim(txtsearch), durations, branchid, filter_status, filter_transporter);
        if (result.data.result == 'ok') {
            let {rows, count} = JSON.parse(result.data.message);
            this.mDataArray = rows;
            this.totalItems = count;
            _.delay(()=> {
              this.dataTableLoading = false;
              this.dialogloading = false;
            }, 500)
            rows = null;
            result = null;
        } else {
          this.mDataArray = [];
          this.dataTableLoading = false;
          this.dialogloading = false;
           // token is expired
          this.$router.push('/logout');
        }
    },
    editItem(item) {
      this.$router.push(`/billing-edit/${item.id}`);
    },
    deleteItem(item) {
      this.selectedItemId = item.id;
      this.selectedItem = `รหัส: ${item.id} ${item.name}`;
      this.confirmDeleteDlg = true;
    },
    changeStatus: _.debounce(async function(item) {
      this.dataTableLoading = true;
      let { id, status } = item;
      let formData = new FormData();
      formData.append("id", id);
      formData.append("status", status);
      let result = await api.setOrderStatus(formData);
      if (result.data.result == "ok") {
        this.snackbar = {
          active: true,
          msg: `รหัส ${item.id} เปลี่ยนสถานะสำเร็จ`
        };
      } else {
        this.snackbarFaild = {
          active: true,
          msg: `รหัส ${item.id} เปลี่ยนสถานะไม่สำเร็จ`
        };
      }
      this.dataTableLoading = false;
      if (this.isadmin) {
        this.$refs.totalCard.loadData();
      }

      this.loadData();
    }, 1000),
    async confirmDelete() {
      let result = await api.deleteOrder(this.selectedItemId);
      if (result.data.result == "ok") {
        if (this.isadmin) {
          this.$refs.totalCard.loadData();
          this.$refs.codCard.loadData();
          this.$refs.tranferCard.loadData();
        }

        this.snackbar = {
          active: true,
          msg: "ลบข้อมูลสำเร็จ"
        };
      } else {
        this.snackbarFaild = {
          active: true,
          msg: "ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง"
        };
      }

      this.confirmDeleteDlg = false; // ซ้อนการแจ้งเตือน dialog
      this.loadData();
    },
    changePaymentMethod: _.debounce(async function(item) {
      this.dataTableLoading = true;
      let { id, is_cod } = item;
      let formData = new FormData();
      formData.append("id", id);
      formData.append("is_cod", is_cod);
      let result = await api.setOrderPaymentMethod(formData);
      if (result.data.result == "ok") {
        this.snackbar = {
          active: true,
          msg: `รหัส ${item.id} เปลี่ยนประเภทชำระเงินสำเร็จ`
        };
      } else {
        this.snackbarFaild = {
          active: true,
          msg: `รหัส ${item.id} เปลี่ยนประเภทชำระเงินไม่สำเร็จ`
        };
      }
      this.dataTableLoading = false;
      if (this.isadmin) {
        this.$refs.codCard.loadData();
        this.$refs.tranferCard.loadData();
      }

      this.loadData();
    }, 1000)
  },
  computed: {
    dateRangeText() {
      return this.dates.join("~");
    },
    isAdminLevel() {
      this.isadmin = this.isShow();
      return this.isadmin; // from userLevel mixins
    }
  },
  watch: {
    options: {
      //เมื่อค่า options ที่เรา sync กับ datable เปลี่ยน method นี้จะทำงานทันที  watch นี้ ชื่อ method ต้องตรงกันกับตัวแปรที่ประกาศข้างบน
      handler(newValue, oldValue) {
        //console.log(this.options.page, this.options.itemsPerPage)
        if (newValue != oldValue) {
           this.loadData();
        }
      },
      deep: true
    },
    search: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          //console.log(this.dates);
          this.searchItems();
        }
      }
    }
  },
  beforeDestroy: function() {
    delete this.mDataArray;
    delete this.selected;
    delete this.branches;
    delete this.options;
    delete this.headers;
  }
};
</script>

<style scoped>
  .my-td-width {
    width: 120px;
  }
  .my-td-width-status {
    width: 130px;
  }
  .my-td-width3 {
    width: 200px;
  }
  .my-select-style2 {
    width:120px;font-size:14px;padding-top:20px;
  }
</style>
